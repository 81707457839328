
import { defineComponent } from "vue";
import { Frame } from "./index.ts";

import { CheckOutlined, EditOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  setup() {
    return { ...Frame() };
  },
  components: {
    CheckOutlined,
    EditOutlined,
  },
});
